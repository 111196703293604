import { makeStyles } from '@material-ui/core';

export default makeStyles(({ breakpoints, resources }) => ({
  layout: {
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
  },
  layoutContainer: {
    position: 'relative',
    minHeight: '100vh',
    flex: '1 1 auto',
    flexDirection: 'column',
    backgroundImage: `url(${resources.background.image})`,
    backgroundRepeat: resources.background.repeat,
    backgroundPosition: resources.background.position,
    backgroundColor: resources.background.color,
    backgroundSize: resources.background.size,
    display: 'flex',
    overflow: 'auto',
    maxWidth: '100%',
    [breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));
