/* eslint-disable max-lines */
import { lazy } from 'react';

import AccessActions from 'src/accessActions';

export default [
  {
    path: '/reload',
    component: lazy(() => import('src/components/Reload')),
  },
  {
    path: '/home',
    component: lazy(() => import('src/containers/Home')),
  },
  {
    path: '/mfa/setting',
    component: lazy(() => import('src/containers/MfaPage')),
  },
  {
    path: '/policy/list',
    exact: true,
    resourceType: AccessActions.PoliciesList,
    component: lazy(() => import('src/containers/PolicyList')),
  },
  {
    path: '/debt-policy/list',
    exact: true,
    resourceType: AccessActions.DebtPoliciesList,
    component: lazy(() => import('src/containers/DebtPolicyList')),
  },
  {
    path: '/quotes/list',
    exact: true,
    resourceType: AccessActions.QuotesList,
    component: lazy(() => import('src/containers/PolicyQuotesList')),
  },
  {
    path: '/dashboard/list/quotes-in-referral',
    exact: true,
    resourceType: AccessActions.DashboardQuotesInReferral,
    component: lazy(() => import('src/containers/ReferralQuotesList')),
  },
  {
    path: '/dashboard/list/renewal-quotes',
    exact: true,
    resourceType: AccessActions.DashboardQuotesRenewal,
    component: lazy(() => import('src/containers/RenewalQuotesList')),
  },
  {
    path: '/dashboard/list/renewal-policies',
    exact: true,
    resourceType: AccessActions.DashboardPolicyRenewal,
    component: lazy(() => import('src/containers/RenewalPolicyList')),
  },
  {
    path: '/policy/new',
    resourceType: AccessActions.QuoteCreate,
    component: lazy(() => import('src/containers/PolicyNew')),
  },
  {
    path: ['/quote/new', '/quote/:id'],
    exact: true,
    component: lazy(() => import('src/containers/PolicyEdit')),
  },
  {
    path: '/policy/edit',
    component: lazy(() => import('src/containers/PolicyEdit/PolicyEditRoute')),
  },
  {
    path: '/policy/claim',
    component: lazy(() => import('src/containers/PolicyClaim')),
  },
  {
    path: '/policy/:id/terminate',
    component: lazy(() => import('src/containers/PolicyTerminate')),
  },
  {
    path: '/policy/:id/renew',
    component: lazy(() => import('src/containers/PolicyRenew')),
  },
  {
    path: '/policy/:id/mta',
    component: lazy(() => import('src/containers/PolicyMta')),
  },
  {
    path: '/policy/:id/change',
    component: lazy(() => import('src/containers/PolicyChange')),
  },
  {
    path: '/policy/:id/:version',
    component: lazy(() => import('src/containers/PolicyHistoryView')),
  },
  {
    path: '/policy/:id',
    component: lazy(() => import('src/containers/PolicyView')),
  },
  {
    path: '/invoice/payments',
    resourceType: AccessActions.PaymentsList,
    component: lazy(() => import('src/containers/Invoicing/Payments')),
  },
  {
    path: '/invoice/consolidated/list',
    resourceType: AccessActions.ConsolidatedInvoicesList,
    component: lazy(() => import('src/containers/Invoicing/Consolidated')),
  },
  {
    path: '/invoice/consolidated/:id',
    resourceType: AccessActions.ConsolidatedInvoiceRead,
    component: lazy(() =>
      import('src/containers/Invoicing/ConsolidatedInvoices/ManageInvoice/Page')
    ),
  },
  {
    path: '/claim/task/list/:tab',
    resourceType: AccessActions.ClaimTaskList,
    component: lazy(() => import('src/containers/ClaimTaskList')),
  },
  {
    path: '/claim/event/list',
    resourceType: AccessActions.ClaimEventList,
    component: lazy(() => import('src/containers/ClaimEventList')),
  },
  {
    path: '/claim/event/new',
    component: lazy(() => import('src/containers/ClaimEventNew')),
    resourceType: AccessActions.ClaimEventNew,
  },
  {
    path: '/claim/event/:id',
    component: lazy(() => import('src/containers/ClaimEvent')),
  },
  {
    path: '/claim/partner/list',
    resourceType: AccessActions.ClaimEventList,
    component: lazy(() => import('src/containers/ClaimPartnerList')),
  },
  {
    path: '/claim/partner/new',
    component: lazy(() => import('src/containers/ClaimPartnerNew')),
    resourceType: AccessActions.ClaimPartnerNew,
  },
  {
    path: '/claim/partner/:id',
    component: lazy(() => import('src/containers/ClaimPartner')),
  },
  {
    path: '/claim/list/:tab',
    resourceType: AccessActions.ClaimList,
    component: lazy(() => import('src/containers/ClaimList')),
  },
  {
    path: '/claim/new',
    resourceType: AccessActions.ClaimCreate,
    component: lazy(() => import('src/containers/ClaimNew')),
  },
  {
    path: '/claim/:id',
    component: lazy(() => import('src/containers/Claim')),
  },
  {
    path: '/invoice/allocations',
    resourceType: AccessActions.InvoicesList,
    component: lazy(() => import('src/containers/Invoicing/Allocations')),
  },
  {
    path: '/report/list',
    exact: true,
    resourceType: AccessActions.BdxReportsList,
    component: lazy(() =>
      import('src/containers/PolicyReport/PolicyReportList')
    ),
  },
  {
    path: '/customer/list',
    exact: true,
    resourceType: AccessActions.CustomersList,
    component: lazy(() => import('src/containers/CustomerList')),
  },
  {
    path: '/customer/new',
    resourceType: AccessActions.CustomersCreate,
    component: lazy(() => import('src/containers/CustomerNew')),
  },
  {
    path: '/customer/:id',
    exact: true,
    component: lazy(() => import('src/containers/Customer')),
  },
  {
    path: '/customer/edit/:id',
    exact: true,
    resourceType: AccessActions.CustomersUpdate,
    component: lazy(() => import('src/containers/CustomerEdit')),
  },

  /** PAYMENT BORDEREAU SECTION */

  {
    path: '/report/list/payment-bordereau',
    resourceType: AccessActions.PaymentReportsList,
    component: lazy(() =>
      import('src/containers/Reporting/PaymentBordereau/List')
    ),
  },
  {
    path: '/report/payment-bordereau/create',
    component: lazy(() =>
      import('src/containers/Reporting/PaymentBordereau/Create')
    ),
    resourceType: AccessActions.PaymentReportsList,
  },
  {
    path: '/report/payment-bordereau/:id/view',
    component: lazy(() =>
      import('src/containers/Reporting/PaymentBordereau/View')
    ),
  },
  {
    path: '/report/payment-bordereau/:id/pay',
    component: lazy(() =>
      import('src/containers/Reporting/PaymentBordereau/Pay')
    ),
  },
  {
    path: '/report/create',
    exact: true,
    component: lazy(() => import('src/containers/PolicyReport/PolicyReport')),
  },
  {
    path: '/report/:id/run',
    component: lazy(() => import('src/containers/PolicyReport/RunReport')),
  },
  {
    path: '/report/:id',
    component: lazy(() => import('src/containers/PolicyReport/PolicyReport')),
  },
  {
    path: '/quick-sight',
    resourceType: AccessActions.ReportsQSSessionEmbed,
    component: lazy(() => import('src/containers/QuickSight')),
  },
  {
    path: '/brokers/list',
    resourceType: AccessActions.BrokersList,
    component: lazy(() => import('src/containers/Brokers/List')),
  },
  {
    path: '/brokers/create',
    component: lazy(() => import('src/containers/Brokers/Create')),
    resourceType: AccessActions.BrokersList,
  },
  {
    path: '/brokers/edit/:shortName',
    component: lazy(() => import('src/containers/Brokers/Edit')),
  },
  {
    path: '/schemas',
    resourceType: AccessActions.SchemesList,
    component: lazy(() => import('src/containers/SchemasList')),
  },
  {
    path: '/schema-constructor/:type/:name/:version/schema/autocomplete',
    component: lazy(() => import('src/containers/ConstructorAutocomplete')),
  },
  {
    path: '/schema-constructor/:type/:name/:version/schema',
    component: lazy(() => import('src/containers/ConstructorSchema')),
  },
  {
    path: '/schema-constructor/:type/:name/:version',
    component: lazy(() => import('src/containers/Constructor')),
  },
  {
    path: '/bdx-import',
    resourceType: AccessActions.PoliciesImport,
    component: lazy(() => import('src/containers/PolicyImport')),
  },
  {
    path: '/claim-import',
    resourceType: AccessActions.ImportsCreate,
    component: lazy(() => import('src/containers/ClaimImport')),
  },
  {
    path: '/high-risk/list',
    resourceType: AccessActions.HighRisksList,
    component: lazy(() => import('src/containers/HighRisk/List')),
  },
  {
    path: '/high-risk/create',
    component: lazy(() => import('src/containers/HighRisk/Create')),
    resourceType: AccessActions.HighRisksList,
  },
  {
    path: '/high-risk/edit/:id',
    component: lazy(() => import('src/containers/HighRisk/Edit')),
  },
  {
    path: '/endorsement-library/list',
    resourceType: AccessActions.EndorsementLibrary,
    component: lazy(() => import('src/containers/EndorsementLibrary/List')),
  },
  {
    path: '/endorsement-library/create',
    component: lazy(() => import('src/containers/EndorsementLibrary/Create')),
    resourceType: AccessActions.EndorsementLibrary,
  },
  {
    path: '/endorsement-library/:id',
    component: lazy(() => import('src/containers/EndorsementLibrary/Edit')),
  },
  {
    path: '/make-model/list',
    resourceType: AccessActions.MakeModelList,
    component: lazy(() => import('src/containers/MakeModel/List')),
  },
  {
    path: '/make-model/create',
    component: lazy(() => import('src/containers/MakeModel/Create')),
    resourceType: AccessActions.MakeModelList,
  },
  {
    path: '/make-model/edit/:id',
    component: lazy(() => import('src/containers/MakeModel/Edit/Edit')),
  },
  {
    path: '/make-model/file-upload',
    component: lazy(() => import('src/containers/MakeModel/FileUpload')),
  },
  {
    path: '/adjustments-library/list',
    component: lazy(() => import('src/containers/AdjustmentsLibrary/List')),
    resourceType: AccessActions.AdjustmentsLibrary,
  },
  {
    path: '/adjustments-library/create',
    component: lazy(() => import('src/containers/AdjustmentsLibrary/Create')),
    resourceType: AccessActions.AdjustmentsLibrary,
  },
  {
    path: '/adjustments-library/edit/:id',
    component: lazy(() => import('src/containers/AdjustmentsLibrary/Edit')),
  },
  {
    path: '/broker-statement',
    component: lazy(() => import('src/containers/BrokerStatement')),
  },
  {
    path: '/user/list',
    resourceType: AccessActions.UmsUsersList,
    component: lazy(() => import('src/containers/UserList')),
  },
  {
    path: '/user/new',
    component: lazy(() => import('src/containers/UserNew')),
    resourceType: AccessActions.UmsUserCreate,
  },
  {
    path: '/user/:email',
    component: lazy(() => import('src/containers/User')),
  },
  {
    path: '/user-import',
    resourceType: AccessActions.UmsUserImport,
    component: lazy(() => import('src/containers/UserImport')),
  },
  {
    path: '/binder/list',
    component: lazy(() => import('src/containers/BinderList')),
  },
  {
    path: '/binder/:id?',
    component: lazy(() => import('src/containers/Binder')),
  },
  {
    path: '/feature/list',
    component: lazy(() => import('src/containers/FeatureList')),
  },
  {
    path: '/feature/:schemaName/list',
    component: lazy(() => import('src/containers/FeatureProductList')),
  },
  {
    path: '/feature/schema-edit/:type/:featureName/:schemaName?',
    component: lazy(() => import('src/containers/FeatureSchemaEdit')),
  },
  {
    path: '/feature/json-edit/:type/:featureName/:schemaName?',
    component: lazy(() => import('src/containers/FeatureJsonEdit')),
  },
  {
    path: '/broker-merge',
    resourceType: AccessActions.BrokerMerge,
    component: lazy(() => import('src/containers/BrokerMerge')),
  },
];
