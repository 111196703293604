import lodash from 'lodash';

import defaultContent from 'src/theme/defaultContent.json';
import { getPublicFeatures } from 'src/api/interfaces/featuresSite';
import { Initializer } from 'src/lib/initializeManager';
import { normalizeTheme } from 'src/theme';

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getLocaleFormat = locale => {
  const localeLists = require('dayjs/locale.json');

  const keyWithRegion = lodash.toLower(`${locale.language}-${locale.region}`);
  const keyLanguage = locale.language;
  const keyDefault = 'en';

  return lodash
    .chain(localeLists)
    .keyBy('key')
    .at([keyWithRegion, keyLanguage, keyDefault])
    .value()
    .find(el => !!el);
};

const getIntl = name => {
  const locale = new Intl.Locale(name ?? 'en-gb');
  const number = new Intl.NumberFormat(locale.baseName, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
    minimumIntegerDigits: 1,
    notation: 'standard',
    numberingSystem: 'latn',
    signDisplay: 'never',
    style: 'decimal',
    useGrouping: 'always',
  });
  const numberPairs = Object.fromEntries(
    number.formatToParts(1_000_000).map(({ type, value }) => [type, value])
  );

  return {
    locale: locale.baseName,
    language: locale.language,
    localeFormat: getLocaleFormat(locale),
    numberGroup: numberPairs.group,
    numberDecimal: numberPairs.decimal,
  };
};

class PublicFeatureService extends Initializer {
  #theme = null;
  #content = null;
  #intl = null;
  #languages = null;
  #timezone = null;

  async initialize() {
    const {
      theme,
      content,
      locale,
      languages,
      timezone,
    } = await getPublicFeatures();

    this.#intl = getIntl(locale);

    this.#content = content ?? defaultContent;

    this.#languages = languages;

    this.#timezone = timezone || BROWSER_TIMEZONE;

    this.#theme = normalizeTheme(theme);
  }

  get theme() {
    return this.#theme;
  }

  get content() {
    return this.#content;
  }

  get intl() {
    return this.#intl;
  }

  get localeKey() {
    return this.#intl.localeFormat.key;
  }

  get languages() {
    return this.#languages;
  }

  get timezone() {
    return this.#timezone;
  }

  dispose() {
    this.#theme = null;
    this.#content = null;
    this.#intl = null;
    this.#languages = null;
    this.#timezone = null;
  }
}

export default new PublicFeatureService();
