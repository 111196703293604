import { lighten } from '@material-ui/core/styles/colorManipulator';

import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    padding: '10px 16px',
    borderBottom: `1px solid ${palette.divider}`,
  },
  head: {
    color: palette.text.primary,
    fontWeight: 900,
  },
  body: {
    color: lighten(palette.text.primary, 0.2),
  },
};
