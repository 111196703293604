import React, { Suspense, useState } from 'react';
import { LinearProgress, Hidden } from '@material-ui/core';

import useLayoutStyles from '../useLayoutStyles';

import LeftBar from './LeftBar';
import TopBar from './TopBar';

export default ({ children }) => {
  const classes = useLayoutStyles();

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openNavBarDesktop, setOpenNavBarDesktop] = useState(true);

  const handleDesktopToggle = (_, open) =>
    setOpenNavBarDesktop(
      typeof open !== 'undefined' ? open : !openNavBarDesktop
    );

  return (
    <div className={classes.layout}>
      <Hidden mdUp>
        <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      </Hidden>
      <LeftBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        onDesktopToggle={handleDesktopToggle}
        openMobile={openNavBarMobile}
        openDesktop={openNavBarDesktop}
      />
      <div className={classes.layoutContainer}>
        <Suspense fallback={<LinearProgress />}>{children}</Suspense>
      </div>
    </div>
  );
};
