import React from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { getAvailableLanguages } from 'src/api/locize';
import publicFeatureService from 'src/service/PublicFeatureService';

export default () => {
  const { languages: tenantLanguages } = publicFeatureService;

  const [languages, setLanguages] = React.useState(tenantLanguages || []);
  const { i18n } = useTranslation();

  const changeLanguage = language => i18n.changeLanguage(language);

  React.useEffect(() => {
    (async () => {
      if (lodash.isEmpty(languages)) {
        const { data } = await getAvailableLanguages();
        const availableLanguages = Object.entries(data).map(
          ([key, { name }]) => ({
            key,
            name,
          })
        );

        setLanguages(availableLanguages);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (languages.length === 1) changeLanguage(languages[0].key);
  }, [languages]);

  return [languages, changeLanguage];
};
