import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  centered: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    '& .spinner > div': {
      backgroundColor: palette.primary.main,
    },
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    background: 'rgba(255,255,255,.5)',
  },
  root: {
    '& .spinner': {
      width: 100,
      textAlign: 'center',
    },
    '& .spinner > div': {
      width: 14,
      height: 14,
      backgroundColor: palette.primary.main,
      borderRadius: '100%',
      display: 'inline-block',
      animation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
    },
    '& .spinner .bounce1': {
      animationDelay: '-0.32s',
      marginLeft: 10,
    },
    '& .spinner .bounce2': {
      animationDelay: '-0.16s',
      marginLeft: 10,
    },
    '& .spinner .bounce3': {
      marginLeft: 10,
    },
  },
  '@global @keyframes sk-bouncedelay': {
    '0%, 80%, 100%': { transform: 'scale(0)' },
    '40%': { transform: 'scale(1.0)' },
  },
}));

export default ({ className, centered = true, overlay = false }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.centered]: centered,
          [classes.overlay]: overlay,
        },
        className
      )}
    >
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};
