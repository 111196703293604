import axios from 'axios';

import { getFileName } from './contentDisposition';

const ABORTED_ERROR_CODE = 'ECONNABORTED';

export default class Request {
  static bodyMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

  _host = null;
  _requestConfig = null;
  _commonTransport = axios.create();
  _isolatedTransport = axios.create();

  constructor({ host }) {
    this._host = host;

    this.interceptors();
  }

  interceptors() {
    // Override or extend this method to attach interceptors
  }

  getConfig(method, endpoint, data, config) {
    const isRequestUrl =
      endpoint.startsWith('http') || endpoint.startsWith(this._host);
    const url = isRequestUrl ? endpoint : `${this._host}${endpoint}`;
    const redirect = 'follow';

    this._requestConfig = {
      url,
      method,
      redirect,
      ...config,
    };

    if (!data) return this._requestConfig;

    if (Request.bodyMethods.includes(method))
      return {
        ...this._requestConfig,
        data,
      };

    const params = Object.entries(data).reduce(
      (properties, [property, value]) =>
        value ? { ...properties, [property]: value } : properties,
      {}
    );

    return {
      ...this._requestConfig,
      params,
    };
  }

  doRaw(method, url, body, config) {
    const { isIsolated, ...requestConfig } = this.getConfig(
      method,
      url,
      body,
      config
    );
    const requestTransport = isIsolated
      ? this._isolatedTransport
      : this._commonTransport;

    return requestTransport(requestConfig);
  }

  async do(method, url, body, config) {
    const { data } = await this.doRaw(method, url, body, config);

    return data;
  }

  saveFile(name, url, type) {
    const link = document.createElement('a');

    link.setAttribute('href', url);
    link.setAttribute('download', name);

    if (type) link.setAttribute('type', type);

    link.click();
  }

  download(method, url, params, config) {
    return this.doRaw(method, url, params, {
      ...config,
      responseType: 'blob',
    }).then(({ data, headers }) => {
      const blobUrl = URL.createObjectURL(new Blob([data]));
      const fileName = getFileName(headers['content-disposition']);

      this.saveFile(fileName, blobUrl);

      URL.revokeObjectURL(blobUrl);
    });
  }

  singleton(method) {
    let source;

    return (url, body, config) => {
      if (source) source.cancel();

      source = axios.CancelToken.source();

      return this.do(method, url, body, {
        ...config,
        cancelToken: source.token,
      });
    };
  }

  cancelable(method, url, params, config) {
    const source = axios.CancelToken.source();

    const cancel = () => source.cancel();
    const make = () =>
      this.do(method, url, params, {
        ...config,
        cancelToken: source.token,
      });

    return {
      cancel,
      make,
    };
  }

  isCanceled(error) {
    return axios.isCancel(error) || error.code === ABORTED_ERROR_CODE;
  }
}
