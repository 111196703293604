import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  TextField,
  Button,
  makeStyles,
  // Divider,
  // Typography,
  // useTheme,
} from '@material-ui/core';
import lodash from 'lodash';

import { FORGOT_PASSWORD_PAGE } from 'src/constants';
import { login } from 'src/redux/modules/auth';
import { Form, AuthError } from 'src/components/AuthForm';
import PasswordField from 'src/components/PasswordField';
import useAsyncDispatch from 'src/hooks/useAsyncDispatch';
import { getIdentityProviders } from 'src/api/interfaces/featuresSite';

import { ChallengeName, signInRequest } from '../requestHelper';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  submitButton: {
    height: 48,
    marginTop: spacing(3),
    textTransform: 'none',
    width: '100%',
    fontWeight: typography.fontWeightMedium,
    fontSize: 18,
  },
  providersButton: {
    backgroundColor: 'transparent !important',
  },
  providersLabel: {
    textTransform: 'capitalize',
  },
  providersIcon: {
    '& > img': {
      width: '20px',
      height: '20px',
      verticalAlign: 'middle',
    },
  },
  forgotPasswordLink: {
    lineHeight: '26px',
    textDecorationLine: 'underline',
    color: palette.primary.main,
    opacity: '0.8',
    fontSize: 16,
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(2, 0),
  },
  divider: {
    flexGrow: 1,
    height: '1px',
    margin: spacing(0, 1),
    backgroundColor: palette.primary.main,
  },
  orText: {
    margin: spacing(0, 1),
  },
}));

export default ({ value, action }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const history = useHistory();
  // const theme = useTheme();

  const [isLoading, asyncDispatch] = useAsyncDispatch();
  const [error, setError] = React.useState(null);
  const [identityProviders, setIdentityProviders] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const data = await getIdentityProviders();

      if (data?.providers) setIdentityProviders(data.providers);
    })();
  }, []);

  // const ProviderIcon = ({ logo }) => (
  //   <img
  //     src={theme.resources.page.login.authForm?.icon?.providers[logo]}
  //     alt="ProviderIcon"
  //   />
  // );

  const handleChange = ({ target }) => {
    const { name, value } = target;

    action.setCredentials({
      [name]: value,
    });
  };

  // const handleProvidersSignIn = provider => {
  //   history.push('/iprovider-auth/' + provider);
  // };

  const handleSubmit = event => {
    event.preventDefault();
    setError(null);

    asyncDispatch(async dispatch => {
      try {
        const loginData = await signInRequest(value.credentials);
        const { authenticationResult, challengeName } = loginData;

        if (!!authenticationResult)
          return login(authenticationResult)(dispatch);
        if (!challengeName) return;

        action.setLoginData(loginData);

        if (challengeName === ChallengeName.NEW_PASSWORD_REQUIRED)
          action.goToFirstLogin();
        if (challengeName === ChallengeName.SOFTWARE_TOKEN_MFA)
          action.goToMfaLogin();
        if (challengeName === ChallengeName.MFA_SETUP) action.goToMfaSetup();
      } catch (error) {
        setError(error);
        console.error(error);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <AuthError error={error} />
      <TextField
        type="email"
        fullWidth
        label={t('Email')}
        name="username"
        required
        disabled={isLoading}
        variant="outlined"
        value={value.credentials.username}
        onChange={handleChange}
      />
      <PasswordField
        label={t('New password')}
        name="password"
        autoComplete="current-password"
        value={value.credentials.password}
        disabled={isLoading}
        onChange={handleChange}
        showEye={true}
      />
      <Link to={FORGOT_PASSWORD_PAGE} className={classes.forgotPasswordLink}>
        {t('Reset password?')}
      </Link>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        type="submit"
        disabled={isLoading}
      >
        {t('Sign in')}
      </Button>
      {!lodash.isEmpty(identityProviders) && (
        <>
          {/* <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
            <Typography variant="body1" className={classes.orText}>
              {t('or')}
            </Typography>
            <Divider className={classes.divider} />
          </div> */}
          {/* {identityProviders.map(({ provider, logo }) => (
            <Button
              key={provider}
              variant="outlined"
              startIcon={<ProviderIcon logo={logo} />}
              classes={{
                root: classes.providersButton,
                label: classes.providersLabel,
                startIcon: classes.providersIcon,
              }}
              onClick={() => handleProvidersSignIn(provider)}
            >
              {t(`Sign in with X`)}
            </Button>
          ))} */}
        </>
      )}
    </Form>
  );
};
