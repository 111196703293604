import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

import SelectButton from './components/SelectButton';
import LinkButton from './components/LinkButton';

const menuItemPaddingLeft = 12;
const iconWidth = 21;
const iconMarginRight = 16;

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: props => ({
    width: '100%',
    padding: '12px 8px',
    paddingLeft:
      props.depth > 0
        ? menuItemPaddingLeft + iconWidth + iconMarginRight * props.depth
        : menuItemPaddingLeft,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    color: theme.color.inversion.text.regular,
    fontWeight: theme.font.weight.regular,
    fontSize: 14,
    '&:hover': {
      opacity: 0.5,
    },
    fontFamily: 'Inter',
  }),
  active: {
    '&$button': {
      opacity: 0.5,
    },
  },
  buttonLeaf: {
    width: '100%',
    padding: '12px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    color: theme.color.inversion.text.regular,
    fontWeight: theme.font.weight.regular,
  },
  iconContainer: {
    marginRight: theme.spacing(2),
  },
  icon: {
    color: theme.font.weight.regular,
    display: 'block',
    margin: 'auto',
    width: 21,
    height: 21,
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  tooltip: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '26px',
    color: '#D0D0D0',
    backgroundColor: '#121212',
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    filter: 'drop-shadow(0px 2px 8px #00000026)',
  },
}));

export default ({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp = false,
  label: Label,
  expanded = true,
  onDesktopToggle,
  component,
  isActive,
  ...props
}) => {
  const classes = useStyles({ depth, Icon });
  const [open, setOpen] = useState(openProp);
  const [showTooltip, setShowTooltip] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleToggle = (
    { disableNavigation } = { disableNavigation: false }
  ) => {
    onDesktopToggle({}, true);
    setOpen(prevOpen => !prevOpen);

    if (disableNavigation) return;

    if (href) history.push(href);
  };

  const action = event => {
    event.preventDefault();
    if (href === location.pathname) history.push(`/reload`);
    else history.push(href);
  };

  useEffect(() => {
    if (!expanded) setOpen(false);
  }, [expanded]);

  return (
    <Tooltip
      title={title}
      placement="right"
      open={!expanded && showTooltip}
      disableHoverListener
      classes={{ tooltip: classes.tooltip }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <ListItem
        {...props}
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        onClick={() => setShowTooltip(false)}
      >
        {children ? (
          <SelectButton
            title={title}
            open={open}
            onToggle={handleToggle}
            icon={Icon}
            classes={classes}
            disableRipple={!expanded}
            isActive={isActive}
            href={href}
          >
            {children}
          </SelectButton>
        ) : (
          <LinkButton
            href={href}
            title={title}
            icon={Icon}
            Label={Label}
            onClick={action}
            classes={classes}
            component={component}
            disableRipple={!expanded}
            isActive={isActive}
          />
        )}
      </ListItem>
    </Tooltip>
  );
};
