import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from 'src/helpers/objects';
import tenantTagService from 'src/service/TenantTagService';
import awsService from 'src/service/AwsService';
import decodeJWT from 'src/helpers/decodeJWT';

import * as IdentifierApi from '../identifier';

export async function login(credentials) {
  const formattedCredentials = convertKeysToSnakeCase(credentials);
  const { tenantTag } = tenantTagService;

  try {
    const response = await IdentifierApi.login(tenantTag, formattedCredentials);

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function challenge(challengeRequest) {
  const formattedRequest = convertKeysToSnakeCase(challengeRequest);
  const { tenantTag } = tenantTagService;

  try {
    const response = await IdentifierApi.challenge(tenantTag, formattedRequest);

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function resetPassword(username) {
  const { tenantTag } = tenantTagService;

  try {
    const response = await IdentifierApi.resetPassword(tenantTag, { username });

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function updatePassword(data) {
  const formattedData = convertKeysToSnakeCase(data);
  const { tenantTag } = tenantTagService;

  try {
    await IdentifierApi.updatePassword(tenantTag, formattedData);
  } catch (error) {
    throw error.response.data;
  }
}

export async function enableMfa(email, session = null) {
  const { tenantTag } = tenantTagService;
  const { accessToken } = awsService;
  const qrCodeSize = 512;
  const qrCodeLabel = `${tenantTag} (${email})`;

  const accessTokenRequest = convertKeysToSnakeCase({
    qrCodeSize,
    qrCodeLabel,
    accessToken,
    session,
  });

  try {
    const response = await IdentifierApi.enableMfa(accessTokenRequest);

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function verifyMfa(code, session = null) {
  const { accessToken } = awsService;
  const verifyRequest = convertKeysToSnakeCase({
    accessToken,
    userCode: code,
    session,
  });

  try {
    const response = await IdentifierApi.verifyMfa(verifyRequest);

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function disableMfa() {
  const { accessToken } = awsService;
  const disableRequest = convertKeysToSnakeCase({ accessToken });

  try {
    await IdentifierApi.disableMfa(disableRequest);
  } catch (error) {
    throw error.response.data;
  }
}

export async function refreshToken(config) {
  const { refreshToken, accessToken } = awsService;
  const { tenantTag } = tenantTagService;

  const { username } = decodeJWT(accessToken);
  const refreshTokenRequest = convertKeysToSnakeCase({
    username,
    refreshToken,
  });

  try {
    const response = await IdentifierApi.refreshToken(
      tenantTag,
      refreshTokenRequest,
      config
    );

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function validateToken() {
  const { tenantTag } = tenantTagService;
  const { accessToken } = awsService;

  if (!accessToken) return false;

  const validateTokenRequest = convertKeysToSnakeCase({
    accessToken,
  });

  try {
    await IdentifierApi.validateToken(tenantTag, validateTokenRequest);

    return true;
  } catch (error) {
    return false;
  }
}

export async function logout() {
  try {
    return await IdentifierApi.logout();
  } catch (error) {
    throw error.response.data;
  }
}
