import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyle = makeStyles(theme => ({
  arrowContainer: {
    ...theme.mixin.flexCenter,
    width: 38,
    height: 38,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: theme.color.base.brand.regular,
  },
  arrowIcon: {
    transition: 'all .2s',
    color:
      theme.resources.page.home.leftBar.arrowIcon.color ||
      theme.palette.primary.contrastText,
    fontSize: '1rem',
  },
  arrowIconExpanded: {
    transform: 'rotate(10) translate(0, 0)',
  },
  arrowIconCollapsed: {
    transform: 'rotate(180deg) translate(-8px, 0)',
  },
}));

const ArrowIcon = ({ onClick, className, expanded }) => {
  const classes = useStyle();

  return (
    <div onClick={onClick} className={clsx(classes.arrowContainer, className)}>
      <ArrowLeftIcon
        className={clsx(classes.arrowIcon, {
          [classes.arrowIconExpanded]: expanded,
          [classes.arrowIconCollapsed]: !expanded,
        })}
      />
    </div>
  );
};

ArrowIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

export default ArrowIcon;
