import { ledger } from 'src/api/endpoint';

export function fetch(page, limit, searchterm) {
  return ledger.get('/sales/risks/high-risk', { page, limit, searchterm });
}

export function getItemData(id) {
  return ledger.get(`/sales/risks/high-risk/${id}`);
}

export function edit(id, data) {
  return ledger.put(`/sales/risks/high-risk/${id}`, data);
}

export function insert(data) {
  return ledger.post('/sales/risks/high-risk', data);
}
