import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import LoginLogo from 'src/components/LoginLogo';
import publicFeatureService from 'src/service/PublicFeatureService';

import LanguageSwitch from './LanguageSwitch';
import Form from './Form';

const useStyles = makeStyles(
  ({ palette, spacing, resources, breakpoints }) => ({
    authContainer: {
      height: '100vh',
    },
    logoContainer: {
      marginRight: spacing(3),
    },
    shortLogoContainer: {
      position: 'absolute',
      right: '5%',
      top: '5%',
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    authForm: {
      position: 'relative',
      padding: spacing(4),
      background: palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    authFormGap: {
      justifyContent: 'flex-start',
      gap: spacing(22),
      [breakpoints.down('sm')]: {
        gap: spacing(11),
      },
    },
    authInfo: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: resources.page.login.authInfo.justifyContent,
      padding: resources.page.login.authInfo.padding || spacing(9),
      backgroundImage: resources.page.login.authInfo.backgroundImage
        ? `url(${resources.page.login.authInfo.backgroundImage})`
        : 'none',
      backgroundRepeat: resources.page.login.authInfo.backgroundRepeat,
      backgroundPosition: resources.page.login.authInfo.backgroundPosition,
      backgroundColor: resources.page.login.authInfo.backgroundColor,
      backgroundSize: resources.page.login.authInfo.backgroundSize,
      '& > *': {
        color: palette.common.white,
        margin: `${spacing(2)}px 0`,
      },
      [breakpoints.down('sm')]: {
        padding: `0 ${spacing(2)}px`,
        '& > *': {
          margin: `${spacing(1)}px 0`,
        },
      },
    },
    authTagLine: {
      color: resources.page.login.authTagLine.color,
    },
    authTitle: {
      textShadow: resources.page.login.authTitle.textShadow,
      color: resources.page.login.authTitle.color,
      fontStyle: resources.page.login.authTitle.fontStyle,
      [breakpoints.down('sm')]: {
        fontSize: '27px',
        lineHeight: '30px',
      },
    },
    authDescription: {
      fontSize: resources.page.login.authDescription.fontSize,
      lineHeight: '26px',
      color: resources.page.login.authDescription.color,
      [breakpoints.down('sm')]: {
        fontSize: resources.page.login.authDescription.sm.fontSize,
      },
    },
  })
);

export default props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { logoSrc, shortLogoSrc } = theme.resources.logo.primary;
  const { content } = publicFeatureService;
  const { title, description, tagline, disclaimer } = content.login;

  return (
    <>
      {shortLogoSrc && (
        <div className={classes.shortLogoContainer}>
          <LoginLogo src={shortLogoSrc} />
        </div>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.authContainer}
      >
        <Grid
          item
          sm={3}
          className={clsx(classes.authForm, {
            [classes.authFormGap]: !disclaimer,
          })}
        >
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item className={classes.logoContainer}>
              <LoginLogo src={logoSrc} />
            </Grid>
            <Grid item>
              <LanguageSwitch />
            </Grid>
          </Grid>
          <Grid item>
            {props.customForm ? props.children : <Form {...props} />}
          </Grid>
          {!!disclaimer && (
            <Grid item>
              <Typography>{t(disclaimer)}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item sm={9} className={classes.authInfo}>
          {tagline && (
            <Typography
              component="h3"
              variant="h3"
              className={classes.authTagLine}
            >
              {t(tagline)}
            </Typography>
          )}
          {title && (
            <Typography
              component="h1"
              variant="h1"
              className={classes.authTitle}
            >
              {t(title)}
            </Typography>
          )}

          {description?.map((textRow, index) => (
            <Typography
              key={`auth-message-row-${index}`}
              className={classes.authDescription}
            >
              {t(textRow)}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
