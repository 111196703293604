import tenantTagService from 'src/service/TenantTagService';
import * as SiteFeatureApi from 'src/api/featuresSite';

/* Schema feature */

export async function getSchemaFeatures() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getSchemaFeatures(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getSchemaFeature(schemaName, schemaType, featureName) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getSchemaFeature({
      tenantTag,
      schemaType,
      schemaName,
      featureName,
    });
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateSchemaFeature(
  schemaName,
  schemaType,
  featureName,
  payload
) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.updateSchemaFeature({
      tenantTag,
      schemaType,
      schemaName,
      featureName,
      payload,
    });
  } catch (error) {
    throw error.response.data;
  }
}

export async function getEnabledFeatures(schemaType) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getEnabledFeatures(tenantTag, schemaType);
  } catch (error) {
    throw error.response.data;
  }
}

/* Product feature */

export async function getProductFeatures() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getProductFeatures(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getProductFeature(schemaName, featureName) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getProductFeature({
      tenantTag,
      schemaName,
      featureName,
    });
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateProductFeature(schemaName, featureName, payload) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.updateProductFeature({
      tenantTag,
      schemaName,
      featureName,
      payload,
    });
  } catch (error) {
    throw error.response.data;
  }
}

/* Claim feature */

export function getClaimFeature(schemaName, featureName) {
  const { tenantTag } = tenantTagService;

  return SiteFeatureApi.getClaimFeature(tenantTag, schemaName, featureName);
}

/* Feature */

export async function getFeatures() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getFeatures(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getPublicFeatures() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getPublicFeatures(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeatureByName(featureName) {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getFeatureByName(tenantTag, featureName);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getIdentityProviders() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getIdentityProviders(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getBrokerStatementsTemplate() {
  const { tenantTag } = tenantTagService;

  try {
    return await SiteFeatureApi.getBrokerStatementsTemplate(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}
