import React from 'react';
import { useHistory } from 'react-router-dom';

const withGoTo = action => () =>
  React.useCallback(action(useHistory().push), []);

export const getPolicyVewLocation = id => `/policy/${encodeURIComponent(id)}`;
export const useGoToPolicyView = withGoTo(goto => id =>
  goto(getPolicyVewLocation(id))
);

export const getPolicyNewLocation = ({ name, type, version }) =>
  `/policy/new?schemaName=${name}&schemaType=${type}&schemaVersion=${version}`;
export const useGoToPolicyNew = withGoTo(goto => schema =>
  goto(getPolicyNewLocation(schema))
);

export const useGoToPolicyHistoryView = withGoTo(goto => (id, version) =>
  goto(`/policy/${encodeURIComponent(id)}/${version}`)
);

export const getPolicyChangeLocation = id =>
  `/policy/${encodeURIComponent(id)}/change`;
export const useGoToPolicyChange = withGoTo(goto => id =>
  goto(getPolicyChangeLocation(id))
);

export const getNewQuoteLocation = () => `/quote/new`;
export const getQuoteEditLocation = () => `/quote`;
export const getQuoteIdEditLocation = id => `/quote/${encodeURIComponent(id)}`;
export const useGoToPolicyQuoteEdit = withGoTo(goto => id =>
  goto(getQuoteIdEditLocation(id))
);

export const getPolicyMtaLocation = id =>
  `/policy/${encodeURIComponent(id)}/mta`;
export const useGoToPolicyMta = withGoTo(goto => id =>
  goto(getPolicyMtaLocation(id))
);

export const getPolicyRenewLocation = id =>
  `/policy/${encodeURIComponent(id)}/renew`;
export const useGoToPolicyRenew = withGoTo(goto => id =>
  goto(getPolicyMtaLocation(id))
);

export const getPolicyTerminateLocation = id =>
  `/policy/${encodeURIComponent(id)}/terminate`;
export const useGoToPolicyTerminate = withGoTo(goto => id =>
  goto(getPolicyMtaLocation(id))
);

export const useGoToReloadQuoteEdit = withGoTo(goto => id =>
  goto(`/reload?url=${encodeURIComponent(getQuoteIdEditLocation(id))}`)
);

export const useGoToPolicyList = withGoTo(goto => () => goto('/policy/list'));

export const getPolicyClaimPath = () => '/policy/claim';

export const useGoToReportDetails = withGoTo(goto => id =>
  goto(`/report/${encodeURIComponent(id)}`)
);

export const getBrokerEditLocation = shortName =>
  `/brokers/edit/${encodeURIComponent(shortName)}`;
export const useGoToBrokerEdit = withGoTo(goto => shortName =>
  goto(getBrokerEditLocation(shortName))
);

export const getHighRiskEditLocation = id =>
  `/high-risk/edit/${encodeURIComponent(id)}`;
export const useGoToHighRiskEdit = withGoTo(goto => id =>
  goto(getHighRiskEditLocation(id))
);

export const useGoToHighRiskCreate = withGoTo(goto => () =>
  goto('/high-risk/create')
);

export const useGoToCreateEndorsement = withGoTo(goto => () =>
  goto('/endorsement-library/create')
);

export const getEditEndorsementLocation = id =>
  `/endorsement-library/${encodeURIComponent(id)}`;
export const useGoToEditEndorsement = withGoTo(goto => ({ id }) =>
  goto(getEditEndorsementLocation(id))
);

export const getMakeModelEditLocation = id =>
  `/make-model/edit/${encodeURIComponent(id)}`;
export const useGoToMakeModelEdit = withGoTo(goto => id =>
  goto(getMakeModelEditLocation(id))
);

export const useGoToMakeModelCreate = withGoTo(goto => () =>
  goto('/make-model/create')
);

export const useGoToMakeModelFileUpload = withGoTo(goto => () =>
  goto(`/make-model/file-upload`)
);

export const useGoToCreateAdjustment = withGoTo(goto => () =>
  goto('/adjustments-library/create')
);

export const getEditAdjustmentLocation = id =>
  `/adjustments-library/edit/${encodeURIComponent(id)}`;
export const useGoToEditAdjustment = withGoTo(goto => ({ id }) =>
  goto(getEditAdjustmentLocation(id))
);

export const getCustomerVewLocation = id =>
  `/customer/${encodeURIComponent(id)}`;
export const useGoToCustomer = withGoTo(goto => id =>
  goto(getCustomerVewLocation(id))
);

export const useGoToCustomerEdit = withGoTo(goto => id =>
  goto(`/customer/edit/${encodeURIComponent(id)}`)
);

export const useGoToCustomerList = withGoTo(goto => () =>
  goto(`/customer/list`)
);

export const useGoToNewCustomer = withGoTo(goto => () => goto(`/customer/new`));

/* Claim */

export const getClaimNewLocation = ({ name, type }) =>
  `/claim/new?name=${name}&type=${type}`;
export const useGoToClaimNew = withGoTo(goto => schema =>
  goto(getClaimNewLocation(schema))
);

export const getClaimViewLocation = (id, params) => {
  if (params) return `/claim/${encodeURIComponent(id)}?${params}`;

  return `/claim/${encodeURIComponent(id)}`;
};

export const useGoToClaimView = withGoTo(goto => (id, tab) =>
  goto(getClaimViewLocation(id, tab))
);

export const getClaimNewEventPath = () => '/claim/event/new';
export const useGoToNewClaimEvent = withGoTo(goto => () =>
  goto(getClaimNewEventPath())
);

export const getClaimEventPath = id => `/claim/event/${encodeURIComponent(id)}`;
export const useGoToClaimEvent = withGoTo(goto => id =>
  goto(getClaimEventPath(id))
);

export const getClaimEventListPath = () => '/claim/event/list';
export const useGoToClaimEventList = withGoTo(goto => () =>
  goto(getClaimEventListPath())
);

export const getClaimPartnerListPath = () => '/claim/partner/list';
export const useGoToClaimPartnerList = withGoTo(goto => () =>
  goto(getClaimPartnerListPath())
);

export const getClaimNewPartnerPath = () => '/claim/partner/new';
export const useGoToNewClaimPartner = withGoTo(goto => () =>
  goto(getClaimNewPartnerPath())
);

export const getClaimPartnerPath = id =>
  `/claim/partner/${encodeURIComponent(id)}`;
export const useGoToClaimPartner = withGoTo(goto => id =>
  goto(getClaimPartnerPath(id))
);

export const getClaimListPath = (tab = 'all') => `/claim/list/${tab}`;
export const useGoToClaimList = withGoTo(goto => (tab = 'all') =>
  goto(getClaimListPath(tab))
);

export const getClaimTaskListPath = (tab = 'all') => `/claim/task/list/${tab}`;
export const useGoToClaimTaskList = withGoTo(goto => (tab = 'all') =>
  goto(getClaimTaskListPath(tab))
);

/* Public Fnol */

export const getPublicFnolProductsPath = () => '/public/claim/product';
export const useGoToPublicFnolProducts = withGoTo(goto => () =>
  goto(getPublicFnolProductsPath())
);

export const getPublicFnolDocsPath = (name, uuid) =>
  `/public/claim/document/${name}/${uuid}`;
export const useGoToPublicFnolDocs = withGoTo(goto => (name, uuid) =>
  goto(getPublicFnolDocsPath(name, uuid))
);

export const getPublicFnolPath = ({ name, type }) =>
  `/public/claim/${name}/${type}`;
export const useGoToPublicFnol = withGoTo(goto => ({ name, type }) =>
  goto(getPublicFnolPath({ name, type }))
);

/* Binder */

export const useGoToBinderList = withGoTo(goto => () => goto('/binder/list'));

export const useGoToBinderView = withGoTo(goto => id =>
  goto(`/binder/${encodeURIComponent(id)}`)
);

export const useReloadBinderView = withGoTo(goto => id =>
  goto(`/reload?url=${encodeURIComponent(`/binder/${id}`)}`)
);

export const useGoToNewBinderView = withGoTo(goto => () => goto('/binder'));

/* User */

export const getUserVewLocation = email => `/user/${encodeURIComponent(email)}`;
export const useGoToUser = withGoTo(goto => email =>
  goto(getUserVewLocation(email))
);

export const useGotoUserList = withGoTo(goto => () => goto('/user/list'));

export const useGoToNewUser = withGoTo(goto => () => goto('/user/new'));

/* Feature */

export const getFeaturesPath = () => '/feature/list';

export const useGoToFeatures = withGoTo(goto => () => goto(getFeaturesPath()));

export const getProductFeaturesPath = schemaName =>
  `/feature/${schemaName}/list`;

export const useGoToProductFeatures = withGoTo(goto => schemaName =>
  goto(getProductFeaturesPath(schemaName))
);

export const getFeatureSchemaEditPath = (type, featureName, schemaName) =>
  `/feature/schema-edit/${type}/${featureName}${
    schemaName ? `/${schemaName}` : ''
  }`;

export const useGoToFeatureSchemaEdit = withGoTo(
  goto => (type, featureName, schemaName) =>
    goto(getFeatureSchemaEditPath(type, featureName, schemaName))
);

export const getFeatureJsonEditPath = (type, featureName, schemaName) =>
  `/feature/json-edit/${type}/${featureName}${
    schemaName ? `/${schemaName}` : ''
  }`;

export const useGoToFeatureJsonEdit = withGoTo(
  goto => (type, featureName, schemaName) =>
    goto(getFeatureJsonEditPath(type, featureName, schemaName))
);

/* Constructor */

export const getConstructorPath = (type, name, version) =>
  `/schema-constructor/${type}/${name}/${version}`;

export const useGoToConstructor = withGoTo(goto => (type, name, version) =>
  goto(getConstructorPath(type, name, version))
);

export const getSchemaEditPath = (type, name, version) =>
  `/schema-constructor/${type}/${name}/${version}/schema`;

export const useGoToSchemaEdit = withGoTo(goto => (type, name, version) =>
  goto(getSchemaEditPath(type, name, version))
);

export const getAutocompleteEditPath = (type, name, version) =>
  `/schema-constructor/${type}/${name}/${version}/schema/autocomplete`;

export const useGoToAutocompleteEdit = withGoTo(goto => (type, name, version) =>
  goto(getAutocompleteEditPath(type, name, version))
);

export const getInvoiceListPath = () => `/invoice/consolidated/list`;

export const useGoToInvoiceList = withGoTo(goto => () =>
  goto(getInvoiceListPath())
);

export const getConsolidatedInvPath = id => `/invoice/consolidated/${id}`;

export const useGoToConsolidatedInv = withGoTo(goto => id =>
  goto(getConsolidatedInvPath(id))
);
