import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

import useIconMask from '../useIconMask';

export default ({
  href,
  title,
  icon: Icon,
  Label,
  classes,
  component,
  isActive,
  ...props
}) => {
  const { t } = useTranslation();
  const iconMaskStyles = useIconMask(Icon);

  return (
    <ButtonBase
      activeClassName={classes.active}
      className={classes.button}
      component={component || NavLink}
      exact
      isActive={isActive}
      to={href}
      {...props}
    >
      {Icon && (
        <div className={classes.iconContainer}>
          <div className={clsx(classes.icon, iconMaskStyles.iconMask)} />
        </div>
      )}
      <div className={classes.truncate}>{t(title)}</div>
      {Label && (
        <span className={classes.label}>
          <Label />
        </span>
      )}
    </ButtonBase>
  );
};
