import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import publicFeatureService from 'src/service/PublicFeatureService';

export default ({ children }) => {
  const locale = require(`dayjs/locale/${publicFeatureService.localeKey}`);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};
