import { sites, sitesPublic } from 'src/api/endpoint';

/* Schema feature */

export function getSchemaFeatures(tenantTag) {
  return sites.get(`/scheme-features/${tenantTag}`);
}

export function getSchemaFeature({
  tenantTag,
  schemaType,
  schemaName,
  featureName,
}) {
  return sites.get(
    `/scheme-features/${tenantTag}/${schemaType}/${schemaName}/${featureName}`
  );
}

export function updateSchemaFeature({
  tenantTag,
  schemaType,
  schemaName,
  featureName,
  payload,
}) {
  return sites.post(
    `/scheme-features/${tenantTag}/${schemaType}/${schemaName}/${featureName}`,
    payload
  );
}

export function getEnabledFeatures(tenantTag, schemaType) {
  return sites.get(`/scheme-features/${tenantTag}/${schemaType}`);
}

/* Product feature */

export function getProductFeatures(tenantTag) {
  return sitesPublic.get(`/product-features/${tenantTag}`);
}

export function getProductFeature({ tenantTag, schemaName, featureName }) {
  return sites.get(
    `/product-features/${tenantTag}/${schemaName}/${featureName}`
  );
}

export function updateProductFeature({
  tenantTag,
  schemaName,
  featureName,
  payload,
}) {
  return sites.put(
    `/product-features/${tenantTag}/${schemaName}/${featureName}`,
    payload
  );
}

/* Claim feature */

export function getClaimFeature(tenantTag, schemaName, featureName) {
  return sites.get(`/claim-features/${tenantTag}/${schemaName}/${featureName}`);
}

/* Feature */

export function getFeatures(tenantTag) {
  return sitesPublic.get(`/features/${tenantTag}`);
}

export function getPublicFeatures(tenantTag) {
  return sitesPublic.get(`/features/${tenantTag}`);
}

export function getFeatureByName(tenantTag, featureName) {
  return sites.get(`/features/${tenantTag}/${featureName}`);
}

export function getIdentityProviders(tenantTag) {
  return sitesPublic.get(`/features/${tenantTag}/identity_providers`);
}

export function getBrokerStatementsTemplate(tenantTag) {
  return sites.get(`/features/${tenantTag}/broker_statements_template`);
}
