import React from 'react';

import Subscriber from 'src/lib/subscriber';

import * as scheduler from './scheduler';
import {
  TRACK_EVENTS,
  TRACK_STORAGE_NAME,
  ACTIVITY_TIME_DELAY,
  LOGOUT_DELAY,
} from './constants';

const initTrackTime = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime) localStorage.setItem(TRACK_STORAGE_NAME, currentTime);
};

const getDuration = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime) return null;

  return currentTime - trackTime;
};

const trackTime = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime || trackTime < currentTime)
    localStorage.setItem(TRACK_STORAGE_NAME, currentTime);
};

const checkTime = subscriber => {
  const duration = getDuration();

  if (duration) subscriber.publish(duration);
};

const isSessionTimeout = () => {
  const duration = getDuration();

  return duration > LOGOUT_DELAY;
};

export default () => {
  const [isActive, setActivity] = React.useState(true);
  const [subscriber] = React.useState(() => new Subscriber());

  const [trackPlanner] = React.useState(() =>
    scheduler.throttle(trackTime, ACTIVITY_TIME_DELAY)
  );
  const [checkPlanner] = React.useState(() =>
    scheduler.timeout(checkTime, ACTIVITY_TIME_DELAY)
  );

  const activityEventListener = React.useCallback(
    () => trackPlanner.start(subscriber),
    []
  );

  const subscribeActivity = () =>
    TRACK_EVENTS.forEach(eventName =>
      document.addEventListener(eventName, activityEventListener, {
        capture: true,
      })
    );

  const unsubscribeActivity = () =>
    TRACK_EVENTS.forEach(eventName =>
      document.removeEventListener(eventName, activityEventListener, {
        capture: true,
      })
    );

  const startTracking = () => {
    initTrackTime();
    subscribeActivity();
    checkPlanner.start(subscriber);
  };

  const stopTracking = () => {
    unsubscribeActivity();
    checkPlanner.stop();
    trackPlanner.stop();
  };

  const clearTracking = () => {
    localStorage.removeItem(TRACK_STORAGE_NAME);
  };

  React.useEffect(() => {
    const checkVisibility = () =>
      setActivity(document.visibilityState === 'visible');

    document.addEventListener('visibilitychange', checkVisibility);

    return () =>
      document.removeEventListener('visibilitychange', checkVisibility);
  }, []);

  return {
    isActive,
    subscriber,
    getDuration,
    stopTracking,
    clearTracking,
    startTracking,
    isSessionTimeout,
  };
};
