import { identifier, identifierPublic } from 'src/api/endpoint';

export function login(tenantTag, credentials) {
  return identifierPublic.post(`/login/${tenantTag}`, credentials);
}

export function challenge(tenantTag, data) {
  return identifierPublic.post(`/auth/challenge/${tenantTag}`, data);
}

export function resetPassword(tenantTag, data) {
  return identifierPublic.post(`/password/reset/${tenantTag}`, data);
}

export function updatePassword(tenantTag, data) {
  return identifierPublic.post(`/password/update/${tenantTag}`, data);
}

export function enableMfa(data) {
  return identifierPublic.post('/mfa/totp/enable', data);
}

export function verifyMfa(data) {
  return identifierPublic.post('/mfa/totp/verify', data);
}

export function disableMfa(data) {
  return identifierPublic.post('/mfa/totp/disable', data);
}

export function refreshToken(tenantTag, data, config) {
  return identifierPublic.post(`/token/refresh/${tenantTag}`, data, config);
}

export function validateToken(tenantTag, data) {
  return identifierPublic.post(`/token/validate/${tenantTag}`, data, {
    isIsolated: true,
  });
}

export function logout() {
  return identifier.get(`/logout`, null, { isIsolated: true });
}
