import { Initializer } from 'src/lib/initializeManager';
import { getFeatureByName } from 'src/api/interfaces/featuresSite';

class PrivateFeatureService extends Initializer {
  #schemaValidation = null;

  async initialize() {
    const [schemaValidation] = await Promise.all([
      getFeatureByName('json_schema_validation'),
    ]);

    this.#schemaValidation = schemaValidation;
  }

  get schemaValidation() {
    return this.#schemaValidation;
  }

  dispose() {
    this.#schemaValidation = null;
  }
}

export default new PrivateFeatureService();
