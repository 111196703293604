import React from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MfaCard from 'src/containers/MfaPage/MfaCard';
import * as IdentifierApi from 'src/api/interfaces/identifier';

const useStyles = makeStyles({
  title: {
    fontSize: '20px',
    textAlign: 'center',
  },
});

const DIALOG_TITLE = 'MFA is required and to proceed, you need to set it up';

export default ({ value, action }) => {
  const { t } = useTranslation();
  const { credentials, loginData } = value;
  const dialogClasses = useStyles();

  const abortSetup = () => {
    action.setLoginData(null);
    action.goToSignIn();
  };

  const handleSubmit = async (mfaCode, event, session) => {
    event.preventDefault();

    try {
      await IdentifierApi.verifyMfa(mfaCode, session);

      action.goToSignIn();
    } catch (error) {
      abortSetup();
    }
  };

  return (
    <Dialog open fullWidth onClose={abortSetup}>
      <DialogTitle className={dialogClasses.title} disableTypography>
        {t(DIALOG_TITLE)}
      </DialogTitle>
      <DialogContent>
        <MfaCard
          username={credentials.username}
          session={loginData?.data?.session}
          handleSubmitSecureCode={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
