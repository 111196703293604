export default {
  QuoteCreate: 'quote:create',
  QuotesList: 'quotes:list',
  DashboardQuotesInReferral: 'dashboard:quotes:in-referral',
  BrokersList: 'brokers:list',
  SchemesList: 'schemes:list',
  PaymentsList: 'payments:list',
  InvoicesList: 'invoices:list',
  PoliciesList: 'policies:list',
  DebtPoliciesList: 'debt:policies:list',
  PoliciesImport: 'policies:import',
  BdxReportsList: 'bdx-reports:list',
  ReportsQSSessionEmbed: 'reports:qs:session:embed',
  PaymentReportsList: 'payment-reports:list',
  HighRisksList: 'sales:high-risks:list',
  EndorsementLibrary: 'sales:endorsements:list',
  AdjustmentsLibrary: 'sales:adjustments:list',
  MakeModelList: 'sales:make-model:list',
  CustomersList: 'customers:list',
  CustomersCreate: 'customer:create',
  CustomersUpdate: 'customer:update',
  CustomersProfile: 'customers:profile',
  CustomersTotals: 'customers:totals',
  CustomersHistory: 'customers:history',
  CustomersNotesRead: 'customers:notes:read',
  BrokerStatementDownload: 'brokers:statements-report:download',
  ClaimList: 'claims:list',
  ClaimAmounts: 'claims:amounts',
  ClaimObjectList: 'objects:list',
  ClaimObjectCreate: 'objects:create',
  ClaimPersonList: 'persons:list',
  ClaimPersonCreate: 'persons:create',
  ClaimDocumentList: 'documents:list',
  ClaimDocumentCreate: 'documents:create',
  ClaimDocumentGenerate: 'documents:generate',
  ClaimAlarmList: 'alarms:list',
  ClaimCreate: 'fnol:create',
  ClaimShow: 'claims:show',
  ClaimShowFnol: 'fnol:show',
  ClaimReserveAmount: 'reserves:amounts',
  ClaimEventList: 'major-events:list',
  ClaimEventNew: 'major-events:create',
  ClaimPartnerList: 'partners:list',
  ClaimPartnerNew: 'partners:create',
  ClaimCommentList: 'comments:list',
  ClaimCommentCreate: 'comments:create',
  ClaimCommentUpdate: 'comments:update',
  ClaimCommentDelete: 'comments:delete',
  ClaimTaskList: 'tasks:list',
  ClaimTaskCreate: 'tasks:create',
  UmsUsersList: 'ums:users:list',
  UmsUserImport: 'ums:user:import:import',
  BinderList: 'binders:list',
  QuoteDocumentsRemove: 'quotes:documents:remove',
  PolicyDocumentsRemove: 'policies:documents:remove',
  FeatureList: 'features-configuration:product-features',
  BrokerLastPaymentPeriod: 'brokerpayments:last-requested-period',
  DashboardClaimsUnassigned: 'dashboard:claims:unassigned',
  DashboardMyTasksAssigned: 'dashboard:my-tasks:assigned',
  DashboardMyTasksCreated: 'dashboard:my-tasks:created',
  DashboardMyClaimsOpen: 'dashboard:my-claims:open',
  DashboardMyClaimsAlarmed: 'dashboard:my-claims:alarmed',
  DashboardMyClaimsInactive: 'dashboard:my-claims:inactive',
  DashboardMyClaimsRecent: 'dashboard:my-claims:recent',
  DashboardQuotesRenewal: 'dashboard:quotes-renewal',
  DashboardPoliciesRenewal: 'dashboard:policies-renewal',
  ImportsProducts: 'imports:products',
  ImportsCreate: 'imports:create',
  ImportsStatus: 'imports:status',
  ImportsList: 'imports:list',
  PaymentDelete: 'payment:delete',
  ConsolidatedInvoicesList: 'consolidated-invoices:list',
  ConsolidatedInvoiceCreate: 'consolidated-invoices:create',
  ConsolidatedInvoiceRead: 'consolidated-invoices:read',
  PaymentsBalanceRead: 'payments-balance:read',
  ChatSettings: 'chat:settings',
  UmsUserCreate: 'ums:user:create',
  MultiSearch: 'multisearch:search',
  PaymentsAlocate: 'payments:allocate',
  BrokerMerge: 'brokers:merge',
  QuoteEvents: 'quote:events',
  SchemasAiChat: 'schemas:ai-chat',
};
