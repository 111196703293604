import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import useLanguageSwitch from 'src/effects/useLanguageSwitch';

const useStyles = makeStyles(({ palette }) => ({
  languageList: {
    display: 'flex',
    flexDirection: 'row',
  },
  languageItem: {
    cursor: 'pointer',
    color: palette.primary.main,
    textTransform: 'uppercase',
  },
}));

export default () => {
  const classes = useStyles();
  const [languages, changeLanguage] = useLanguageSwitch();

  return (
    <Grid container spacing={2} direction="row" wrap="nowrap">
      {languages.map(language => (
        <Grid
          item
          key={language.key}
          className={classes.languageItem}
          onClick={() => changeLanguage(language.key)}
        >
          {language.key}
        </Grid>
      ))}
    </Grid>
  );
};
