import Download from './Download';
import Cancelable from './Cancelable';

export default class Endpoint {
  _request = null;
  _download = null;
  _cancelable = null;

  constructor(request) {
    this._request = request;
    this._download = new Download(request);
    this._cancelable = new Cancelable(request);
  }

  get(url, params, config) {
    return this._request.do('GET', url, params, config);
  }

  post(url, params, config) {
    return this._request.do('POST', url, params, config);
  }

  put(url, params, config) {
    return this._request.do('PUT', url, params, config);
  }

  patch(url, params, config) {
    return this._request.do('PATCH', url, params, config);
  }

  delete(url, params, config) {
    return this._request.do('DELETE', url, params, config);
  }

  do(method, url, body, config) {
    return this._request.do(method, url, body, config);
  }

  doRaw(method, url, body, config) {
    return this._request.doRaw(method, url, body, config);
  }

  singleton(method) {
    return this._request.singleton(method);
  }

  isCanceled(error) {
    return this._request.isCanceled(error);
  }

  get download() {
    return this._download;
  }

  get cancelable() {
    return this._cancelable;
  }
}
