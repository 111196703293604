import * as HighRisksApi from '../highRisks';

export async function fetch(page, limit, searchterm) {
  try {
    return await HighRisksApi.fetch(page, limit, searchterm);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getItemData(id) {
  try {
    return await HighRisksApi.getItemData(id);
  } catch (error) {
    throw error.response.data;
  }
}

export async function edit(id, data) {
  try {
    return await HighRisksApi.edit(id, data);
  } catch (error) {
    throw error.response.data;
  }
}

export async function insert(data) {
  try {
    return await HighRisksApi.insert(data);
  } catch (error) {
    throw error.response.data;
  }
}
